import React, { useEffect, useRef, createRef, useState } from "react"
import AddVideo from "./AddVideo"
import VideosState from "./index.state"
import Loader, { Loader100vh } from "../../UI/Loader"
import EditableText from "../../UI/EditableText"
import { Title, LockAction, Back, Videos, Wrapper, Video, Content, Delete, Public } from "./index.styles"
import { FormattedDate } from "react-intl"
import { Previous, Trash, Lock, Unlock, } from "grommet-icons"
import { Link } from "gatsby"
import InfiniteScroll from "react-infinite-scroller"
import CurrentUserState from "../../../state/CurrentUserState"

const VideosPage = () => {
  const { filteredVideos: videos, deleteVideo, hasMore, loadMore, loading, updateVideo, publicView, setPublicView } = VideosState.useContainer()
  const { currentUser } = CurrentUserState.useContainer()
  const [loaded, setLoaded] = useState(false)
  // const [portraits, setPortraits] = useState([...videos].map(() => false))
  const videoRefs = useRef([...videos].map(d => false))

  useEffect(() => {
    videoRefs.current = videos.map((v) => {
      return createRef()
    })
    setLoaded(true)
  }, [videos])

  if (loading || !loaded) return <Loader100vh />
  return (
    <Wrapper>
      <Link to="/">
        <Back>
          <Previous color="#fff" size="30px" />
        </Back>
      </Link>
      <Title>
        { currentUser.admin && !publicView && <LockAction onClick={() => setPublicView(true) }>
          <Lock color='#fff' />
        </LockAction> }
        { currentUser.admin && publicView && <LockAction onClick={() => setPublicView(false) }>
          <Unlock color='#fff' />
        </LockAction> }
        <span>Les Videos</span>
      </Title>
      <Content>
        { currentUser.admin && <AddVideo /> }
        <InfiniteScroll
          pageStart={0}
          loadMore={loadMore}
          hasMore={hasMore}
          loader={
            <div className="loader" key={0} style={{
              height: '220px',
              width: '100%'
            }}>
              <Loader />
            </div>
          }
        >
          <Videos>
            {videos.map((d, i) => (
              <Video key={d.id} portrait={true || (videoRefs.current[i] && videoRefs.current[i].videoHeight > videoRefs.current[i].videoWidth)}>
                <div>
                  <video src={d.url} controls id={`video-${d.id}`} ref={videoRefs.current && videoRefs.current[i]} />
                </div>
                <div>
                  <EditableText onEdited={val => updateVideo(d, { title: val })}>{d.title}</EditableText>
                </div>
                <div>
                  <FormattedDate
                    value={new Date(d.createdAt.seconds * 1000)}
                    month="long"
                    day="numeric"
                    weekday="long"
                  />
                </div>
                { currentUser.admin && !d.public && <Public onClick={() => updateVideo(d, { public: true })}>
                  <Lock />
                </Public> }
                { currentUser.admin && d.public && <Public onClick={() => updateVideo(d, { public: false })}>
                  <Unlock />
                </Public> }
                { currentUser.admin && <Delete onClick={() => deleteVideo(d)}>
                  <Trash />
                </Delete> }
              </Video>
            ))}
          </Videos>
        </InfiniteScroll>
      </Content>
    </Wrapper>
  )
}

VideosPage.propTypes = {}

VideosPage.defaultProps = {}

export default () => {
  const { loading } = CurrentUserState.useContainer()
  if (loading) return <Loader100vh />
  return <VideosState.Provider>
    <VideosPage />
  </VideosState.Provider>
}
