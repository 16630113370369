import React from 'react'
import Base from '../layouts/base'
import Videos from '../components/pages/Videos'

const VideosPage = (props) => {
  return <Base><Videos /></Base>
}

VideosPage.propTypes = {

}

VideosPage.defaultProps = {

}

export default VideosPage
