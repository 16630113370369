import { createContainer } from "unstated-next"
import FirebaseState from "../../../state/FirebaseState"
import { useState, useMemo, useEffect } from "react"
import CurrentUserState from "../../../state/CurrentUserState"

const PAGE_SIZE = 5

const useVideos = () => {
  const { db, storage } = FirebaseState.useContainer()
  const { currentUser } = CurrentUserState.useContainer()
  const [videos, setVideos] = useState([])
  const [filteredVideos, setFilteredVideos] = useState([])
  const [publicView, setPublicView] = useState(false)
  const [loading, setLoading] = useState(false)
  const [hasMore, setHasMore] = useState(true)
  const collection = db.collection("videos")

  useEffect(() => {
    if (publicView) {
      setFilteredVideos([...videos].filter(d => d.public === true))
    } else {
      setFilteredVideos([...videos])
    }
  }, [videos, publicView])

  const query = useMemo(() => {
    const cursor = videos[videos.length - 1] && videos[videos.length - 1].createdAt
    let query = collection
    if (currentUser.admin) {
      query = query.orderBy('createdAt', 'desc')
    } else {
      query = query.where("public", "==", true)
      .orderBy('createdAt', 'desc')
    }
    if (videos.length > 0) query = query.startAfter(cursor)
    return query.limit(PAGE_SIZE)
  }, [videos])

  const loadMore = async (page) => {
    
    const snapshot = await query.get()
    if (snapshot.docs.length === 0) return setHasMore(false)
    setVideos([...videos, ...snapshot.docs.map(d => ({ id: d.id, ...d.data() }))])
  }

  const addVideo = (video) => {
    setVideos([video, ...videos])
  }

  const getVideoPath = (video) => {
    return decodeURI(video.url
      .replace('https://firebasestorage.googleapis.com/v0/b/benexperiments.appspot.com/o/videos%2F', '')
      .replace(/\?alt.*$/, ''))
  }

  const deleteVideo = async (video) => {
    const path = getVideoPath(video)
    await storage.ref('videos').child(path).delete()
    await collection.doc(video.id).delete()
    setVideos([...videos].filter(d => d.id !== video.id))
  }

  const updateVideo = async (video, payload) => {
    if (typeof payload.public === 'boolean') {
      const path = getVideoPath(video)
      await storage.ref('videos').child(path).updateMetadata({ public: payload.public  })
    }
    await collection.doc(video.id).update(payload)
    setVideos([...videos].map(p => p.id === video.id ? {...p, ...payload} : {...p}))
  }

  return { videos, filteredVideos, addVideo, deleteVideo, loading, hasMore, loadMore, updateVideo, publicView, setPublicView }
}
const VideosState = createContainer(useVideos)

export default VideosState