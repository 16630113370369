import styled, { css } from "styled-components"
import { colors } from "../../../theme"

export const Wrapper = styled.div`
  position: relative;
  padding: 40px 0;
  background-image: linear-gradient(to left, ${colors.primary}, ${colors.secondary});
`

export const Back = styled.div`
  position: absolute;
  top: -15px;
  left: 15px;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

export const Title = styled.div`
  font-family: 'Dancing Script', cursive;
  font-size: 39px;
  line-height: 39px;
  widows: 100%;
  color: white;
  text-align: center;
  margin-bottom: 25px;
  > :nth-child(2) {
    margin-right: 10px;
  }
`

export const LockAction = styled.span`
  margin-right: 20px;
  cursor: pointer;
`

export const Content = styled.div`
  padding: 0 20px;
`

export const Videos = styled.div`
  display: grid;
  grid-gap: 15px;
  height: 100%;
  grid-template-columns: repeat(auto-fit, minmax(240px, 347px));
`

export const Video = styled.div`
  position: relative;
  background-color: #fff;
  padding: 8px;
  display: flex;
  flex-direction: column;
  box-shadow: 4px 2px 6px rgba(0,0,0,0.2);
  ${props => props.portrait ? css`
      grid-row: span 2;
      > :first-child {
        padding-bottom: 177.6%;
      }
    ` : css`
      grid-column: span 2;
      > :first-child {
        padding-bottom: 66%;
      }
    `}
  > :first-child {
    position: relative;
    width: 100%;

    video {
      position: absolute;
      top: 0;
      left: 0;
      outline: none;
      background-color: #1A1D21;
      width: 100%;
      height: 100%;
    }
  }
  > :nth-child(2) {
    > * {
      display: flex;
      height: 50px;
      justify-content: center;
      align-items: center;
      font-family: 'Dancing Script', cursive;
      font-size: 28px;
    }
  }
  > :nth-child(3) {
    font-family: 'Dancing Script', cursive;
    font-size: 16px;
    text-align: center;
    margin-top: -10px;
  }
`

export const Delete = styled.div`
  position: absolute;
  bottom: 18px;
  right: 20px;
  cursor: pointer;
`

export const Public = styled.div`
  position: absolute;
  bottom: 18px;
  left: 20px;
  cursor: pointer;
`
