import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Button = styled.div`
  cursor: pointer;
  padding: 6px 12px;
  background-color: ${props => props.color};
  border-radius: 8px;
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
  > * {
    margin-right: 10px;
  }
  > :last-child {
    margin-right: 0;
  }
`

Button.propTypes = {

}

Button.defaultProps = {
  color: 'rgba(255, 255, 255, 0.2)'
}

export default Button
