import React, { useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import useVideoUploader from '../../../hooks/useVideoUploader'
import Button from '../../UI/Button'
import VideosState from './index.state'
import styled from 'styled-components'
import { Send, Image, Checkmark } from 'grommet-icons'

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  color: white;
  > :first-child {
    margin-bottom: 20px;
  }


  margin-bottom: 20px;
`

const FileInput = styled.div`
  font-size: 16px;
  width: 210px;
  input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }

  input + label {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    overflow: hidden;
    height: 40px;
    background-color: #d3394c;
    border-radius: 8px;
  }

  input + label:hover {
    background-color: #722040;
  }

  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: none;
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  :last-child {

  }
`

const Form = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 240px;
  > * {
    margin-bottom: 10px;
  }
  > :nth-child(2) {
    box-shadow: rgba(0, 0, 0, 0.2) 1px 1px 5px;
    border-radius: 6px;
    border-width: initial;
    border-style: none;
    border-color: initial;
    border-image: initial;
    padding: 6px 8px;
    :focus {
      outline: none;
    }
  }
  > :last-child {
    margin-bottom: 0;
  }
  label > span { margin-right: 10px; }
`

const SubmitButton = styled(Button)`
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const AddVideo = (props) => {
  const [displayed, setDisplayed] = useState(false)
  const [currentFile, setCurrentFile] = useState()
  
  const { upload, uploading, progress } = useVideoUploader()
  const imageInput = useRef()
  const titleInput = useRef()
  const publicInput = useRef()

  const { addVideo } = VideosState.useContainer()

  const onUpload = async () => {
    const video = await upload(currentFile, titleInput.current.value, publicInput.current.checked)
    addVideo(video)
    titleInput.current.value = ''
    imageInput.current.value = ''
    publicInput.current.checked = false
    setDisplayed(false)
  }

  return <Wrapper>
    <Button onClick={() => setDisplayed(!displayed)}>
      { displayed ? 'Annuler' : 'Ajouter' }
    </Button>
    { displayed && <Form>
      <FileInput>
        <div>
        <input type="file" name="file" id='file' ref={imageInput} onChange={e => setCurrentFile(e.target.files[0])} />
          <label for="file">
            { currentFile
              ? <Checkmark color='#fff' />
              : <Image color='#fff' />
            }
          </label>
        </div>
        { currentFile && <div>{currentFile.name}</div> }
      </FileInput>
      <input type='text' ref={titleInput} placeholder='Titre' />
      <label>
        <span>Publique</span>
        <input type='checkbox' ref={publicInput} />
      </label>
      { uploading && <div>{Math.floor(progress)}%</div> }
      { !uploading && <SubmitButton onClick={onUpload} type='submit'>
        <Send color='#fff' />
      </SubmitButton> }
    </Form> }
  </Wrapper>
}

AddVideo.propTypes = {

}

AddVideo.defaultProps = {

}

export default AddVideo
